<template>
  <!-- 关于我们 -->
  <div class="content">
    <div class="header">
      <img src="../../assets/images/aboutUs.png" alt="" />
      <p>幸福绩效</p>
    </div>
    <pre class="text" v-html="aboutUs"></pre>
  </div>
</template>
<script>
import { about } from '@/api/employeedata.js'
export default {
  name: 'personalCenter-aboutus',
  data() {
    return {
      aboutUs: ''
    }
  },
  created() {
    this.about()
  },
  methods: {
    about() {
      about().then(res => {
        if (res && res.code === '000000') {
          this.aboutUs = res.data.aboutUs
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background: #fff;
  height: calc(100% - 40px);
  padding: 30px;
  margin: 20px 30px;
  overflow-y: auto;
  .header {
    margin-top: 80px;
    text-align: center;
    img {
      width: 80px;
      height: 80px;
    }
    p {
      font-size: 16px;
      color: #333333;
    }
  }
  .text {
    max-width: 856px;
    margin: 40px auto 0;
    font-size: 14px;
    color: #666666;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
</style>
